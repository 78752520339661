import React from 'react';
import { FaRobot, FaChartLine, FaRegLightbulb, FaClipboardList, FaUserCircle, FaPuzzlePiece } from 'react-icons/fa';

const Cards = () => {
  return (
    <div className="container mx-auto">
      <br />
      <h2 className="text-5xl font-bold text-center mb-12 text-white">Neden CafeWare?</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Gelişmiş Otomasyon */}
        <div className="bg-white p-6 rounded-lg shadow-lg transition hover:shadow-2xl">
          <div className="text-indigo-600">
            <FaRobot className="w-12 h-12 mb-4 mx-auto" />
          </div>
          <h3 className="text-xl font-semibold mb-2">Gelişmiş Otomasyon</h3>
          <p className="text-gray-600 text-justify">CafeWare, sektördeki muadillerine kıyasla daha fazla otomasyon imkânı sunar. Sipariş alma, ödeme işlemleri, masa yönetimi gibi rutin operasyonlarınızı kolaylaştırarak, işletmenizin verimliliğini üst düzeye taşır. Böylece, personelinizin daha önemli görevlere odaklanmasını sağlar.</p>
        </div>
        {/* Yapay Zeka Destekli Stok Takibi */}
        <div className="bg-white p-6 rounded-lg shadow-lg transition hover:shadow-2xl">
          <div className="text-indigo-600">
            <FaChartLine className="w-12 h-12 mb-4 mx-auto" />
          </div>
          <h3 className="text-xl font-semibold mb-2">Yapay Zeka Destekli Stok Takibi</h3>
          <p className="text-gray-600 text-justify">CafeWare’in Yapay Zeka destekli stok takibi, malzemelerinizin güncel durumu hakkında anında bilgi sağlar ve stok eksikliklerini önceden tahmin ederek sipariş sürecinizi optimize eder.</p>
        </div>
        {/* Yeni Ürün Öneri Yazılımı */}
        <div className="bg-white p-6 rounded-lg shadow-lg transition hover:shadow-2xl">
          <div className="text-indigo-600">
            <FaRegLightbulb className="w-12 h-12 mb-4 mx-auto" />
          </div>
          <h3 className="text-xl font-semibold mb-2">Yeni Ürün Öneri Yazılımı</h3>
          <p className="text-gray-600 text-justify">CafeWare, satış verilerinizi analiz ederek müşterilerinizin ilgisini çekebilecek yeni ürünler önerir. Bu özellik, menünüzü sürekli güncel tutmanıza ve müşteri memnuniyetini artırmanıza yardımcı olur.</p>
        </div>
        {/* Gelişmiş Raporlama */}
        <div className="bg-white p-6 rounded-lg shadow-lg transition hover:shadow-2xl">
          <div className="text-indigo-600">
            <FaClipboardList className="w-12 h-12 mb-4 mx-auto" />
          </div>
          <h3 className="text-xl font-semibold mb-2">Gelişmiş Raporlama</h3>
          <p className="text-gray-600 text-justify">CafeWare'in gelişmiş raporlama araçları, işletmenizin finansal performansını ayrıntılı bir şekilde analiz eder. Satış trendleri, müşteri tercihleri, ve kârlılık analizleri gibi kritik verileri görselleştirerek, stratejik kararlar almanıza olanak tanır.</p>
        </div>
        {/* Kullanıcı Dostu Arayüz */}
        <div className="bg-white p-6 rounded-lg shadow-lg transition hover:shadow-2xl">
          <div className="text-indigo-600">
            <FaUserCircle className="w-12 h-12 mb-4 mx-auto" />
          </div>
          <h3 className="text-xl font-semibold mb-2">Kullanıcı Dostu Arayüz</h3>
          <p className="text-gray-600 text-justify">CafeWare, kullanıcı dostu ve sezgisel bir arayüz ile tasarlanmıştır. Personelinizin hızlı bir şekilde adapte olabileceği bu arayüz, günlük operasyonları kolaylaştırır ve eğitim sürelerini minimuma indirir.</p>
        </div>
        {/* Entegre Çözümler */}
        <div className="bg-white p-6 rounded-lg shadow-lg transition hover:shadow-2xl">
          <div className="text-indigo-600">
            <FaPuzzlePiece className="w-12 h-12 mb-4 mx-auto" />
          </div>
          <h3 className="text-xl font-semibold mb-2">Entegre Çözümler</h3>
          <p className="text-gray-600 text-justify">CafeWare, çeşitli ödeme sistemleri ve üçüncü parti uygulamalar ile entegrasyon imkanı sunar. Bu sayede, tüm iş süreçlerinizi tek bir platform üzerinden yönetebilirsiniz.</p>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Cards;