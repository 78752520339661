import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/logo.png";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <nav className="fixed top-0 left-0 w-full z-50 bg-[rgba(0,0,0,0.9)]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center text-2xl font-bold">
              <img src={Logo} alt="MetaMind Logo" className="h-8 w-8 mr-2" />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-800 via-purple-500 to-blue-400">
                MetaMind Evolution
              </span>
            </Link>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              <NavLink
                to="/"
                className="text-white hover:text-purple-600 px-3 py-2 rounded-md text-sm font-medium"
                activeClassName="text-gray-900 font-semibold"
              >
                Anasayfa
              </NavLink>
              <div className="relative">
                <button
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  onMouseEnter={() => setDropdownOpen(true)}
                  className="text-white hover:text-purple-600 px-3 py-2 rounded-md text-sm font-medium focus:outline-none"
                >
                  Hizmetlerimiz
                </button>
                {dropdownOpen && (
                  <div
                    className="absolute mt-2 w-48 rounded-md shadow-lg bg-black text-white ring-1 ring-black ring-opacity-5"
                    onMouseLeave={() => setDropdownOpen(false)}
                  >
                    <NavLink
                      to="/chatbot"
                      className="block px-4 py-2 text-sm text-white hover:bg-purple-600"
                      activeClassName="bg-gray-100"
                      onClick={() => setDropdownOpen(false)}
                    >
                      CafeWare
                    </NavLink>
                    <NavLink
                      to="/chatbot"
                      className="block px-4 py-2 text-sm text-white hover:bg-purple-600"
                      activeClassName="bg-gray-100"
                      onClick={() => setDropdownOpen(false)}
                    >
                      StockyWave
                    </NavLink>
                    <NavLink
                      to="/chatbot"
                      className="block px-4 py-2 text-sm text-white hover:bg-purple-600"
                      activeClassName="bg-gray-100"
                      onClick={() => setDropdownOpen(false)}
                    >
                      Hostly
                    </NavLink>
                    <NavLink
                      to="/chatbot"
                      className="block px-4 py-2 text-sm text-white hover:bg-purple-600"
                      activeClassName="bg-gray-100"
                      onClick={() => setDropdownOpen(false)}
                    >
                      GradeUp
                    </NavLink>                    
                    <NavLink
                      to="/chatbot"
                      className="block px-4 py-2 text-sm text-white hover:bg-purple-600"
                      activeClassName="bg-gray-100"
                      onClick={() => setDropdownOpen(false)}
                    >
                      Chatbot
                    </NavLink>
                  </div>
                )}
              </div>
              <NavLink
                to="/about"
                className="text-white hover:text-purple-600 px-3 py-2 rounded-md text-sm font-medium"
                activeClassName="text-gray-900 font-semibold"
              >
                Hakkımızda
              </NavLink>
              <NavLink
                to="/contact"
                className="text-white hover:text-purple-600 px-3 py-2 rounded-md text-sm font-medium"
                activeClassName="text-gray-900 font-semibold"
              >
                İletişim
              </NavLink>
            </div>
          </div>
          <div className="flex md:hidden">
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="text-white hover:text-gray-900 focus:outline-none focus:text-gray-900"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {menuOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
        className={`fixed inset-y-0 left-0 transform ${
          menuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out md:hidden bg-black w-64 shadow-md z-40`}
      >
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <div className="px-3 py-2">
            <Link to="/" className="flex items-center text-xl font-bold text-white">
              <span className="text-xl bg-clip-text text-transparent bg-gradient-to-r from-purple-800 via-purple-500 to-blue-400">
                MetaMind Evolution
              </span>
            </Link>
          </div>
          <hr className="border-white" />
          <NavLink
            to="/"
            className="text-white hover:text-purple-600 block px-3 py-2 rounded-md text-base font-medium"
            activeClassName="text-gray-900 font-semibold"
            onClick={() => setMenuOpen(false)}
          >
            Anasayfa
          </NavLink>
          <hr className="border-purple-700" />
          <div className="relative">
            <button
              onClick={() => setDropdownOpen(!dropdownOpen)}
              className="text-white hover:text-purple-600 block px-3 py-2 rounded-md text-base font-medium w-full text-left focus:outline-none"
            >
              Hizmetlerimiz
            </button>
            {dropdownOpen && (
              <div className="mt-1">
                <NavLink
                  to="/chatbot"
                  className="block px-4 py-2 text-sm text-white hover:bg-yellow-800"
                  activeClassName="bg-gray-100"
                  onClick={() => setMenuOpen(false)}
                >
                  CafeWare
                </NavLink>
                <NavLink
                  to="/chatbot"
                  className="block px-4 py-2 text-sm text-white hover:bg-yellow-800"
                  activeClassName="bg-gray-100"
                  onClick={() => setMenuOpen(false)}
                >
                  StockyWave
                </NavLink>
                <NavLink
                  to="/chatbot"
                  className="block px-4 py-2 text-sm text-white hover:bg-yellow-800"
                  activeClassName="bg-gray-100"
                  onClick={() => setMenuOpen(false)}
                >
                  Chatbot
                </NavLink>
              </div>
            )}
          </div>
          <hr className="border-purple-700" />
          <NavLink
            to="/about"
            className="text-white hover:text-purple-600 block px-3 py-2 rounded-md text-base font-medium"
            activeClassName="text-gray-900 font-semibold"
            onClick={() => setMenuOpen(false)}
          >
            Hakkımızda
          </NavLink>
          <hr className="border-purple-700" />
          <NavLink
            to="/contact"
            className="text-white hover:text-purple-600 block px-3 py-2 rounded-md text-base font-medium"
            activeClassName="text-gray-900 font-semibold"
            onClick={() => setMenuOpen(false)}
          >
            İletişim
          </NavLink>
          <hr className="border-purple-700" />
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 