import React from 'react';
import Typed from 'react-typed';
import { Link } from "react-router-dom";
const Hero = () => {
  return (
    <div className='text-white'>
      <div className='max-w-[800px] mt-[-96px] w-full mx-auto text-center flex flex-col justify-center' style={{ height: '680px' }}>
      <p style={{ fontSize: '25px', marginTop: '130px' }} className='text-[#3bff69] font-bold p-2'>
          Yeni Nesil Yazılım
        </p>
        <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-800 via-purple-500 to-blue-400">          MetaMind Evolution
        </h1>
        <div className='flex justify-center items-center'>
          <p className='md:text-5xl text-[#e7e7e7] sm:text-4xl text-xl font-bold py-4'>
            Şirketinizi geliştirin: 
          </p>
          <Typed
          className='md:text-5xl text-[#ca3bda] sm:text-4xl text-xl font-bold md:pl-4 pl-2'
            strings={['CafeWare', 'StockyWave', 'Chatbot']}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
        </div>
        <p className='md:text-2xl text-xl font-bold text-gray-500'>Şirketinizi, yenil nesil yapay zeka destekli teknolojilerimiz ile geleceğe adapte edin.</p>
        <Link to='/contact' style={{color:'white'}}className='bg-[#df00c5] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black'>İletişime Geçin</Link>
      </div>
    </div>
  );
};

export default Hero;
