import React from 'react';
import '../index.css'; // Ensure this points to the correct CSS file location

const Chatbot = () => {
  return (
    <div className="relative text-white h-screen flex items-center justify-center overflow-hidden">
      {/* Animasyonlu Arka Plan */}
      <div className="absolute inset-0 bg-gradient-to-r from-purple-800 via-black to-blue-900 animate-bgMove bg-[length:200%_200%]"></div>
      <div className="relative max-w-[800px] text-center z-10 p-6">
        {/* Loading Spinner with Text */}
        <div className="spinner-container">
          <div className="spinner"></div>
          <div className="spinner-text">Çok Yakında</div>
        </div>
      </div>
      {/* Ekstra Arka Plan Efektleri */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-40 z-0"></div>
    </div>
  );
};

export default Chatbot;