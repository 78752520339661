import React from 'react';
import CafeWareMain from '../assets/profile.jpg';

const teamMembers = [
  {
    name: 'Behiç Oytun Şenkul',
    role: 'CEO',
    image: CafeWareMain,
    bio: 'MetaMind Evolution Kurucusu',
  },
  // You can add more team members here as needed
];

const About = () => {
  return (
    <div className="bg-white">
    <div className="container mx-auto px-4 py-16 text-white">
      {/* Hero Section */}
      <br/>
      <h2 className="text-3xl font-semibold mb-6 animate-fade-in-down text-center lg:text-center text-black">Hakkımızda</h2>
      <div className="relative inset-0 bg-gradient-to-r from-purple-700 via-pink-500 to-blue-500 animate-bgMove bg-[length:200%_200%] text-white text-center py-24 mb-16 rounded-lg shadow-lg overflow-hidden">
        <div className="absolute inset-0 bg-black bg-opacity-40 mix-blend-multiply"></div>
        <div className="relative z-10">
          <p className="text-lg max-w-2xl mx-auto animate-fade-in-up">
            Güncelleniyor...
          </p>
        </div>
      </div>

      {/* Vision & Mission Section */}
      <h2 className="text-3xl font-semibold mb-6 animate-fade-in-down text-center lg:text-center text-black">Vizyonumuz ve Misyonumuz</h2>
      <div className="inset-0 bg-gradient-to-r from-purple-700 via-pink-500 to-blue-500 animate-bgMove bg-[length:200%_200%] mb-12 px-4 py-16 rounded-lg shadow-lg">
        <div className="flex flex-col lg:flex-row lg:space-x-12">
          <div className="mb-8 lg:mb-0 lg:w-1/2 text-center lg:text-left animate-fade-in-up">
            <h3 className="text-xl font-semibold mb-4">Vizyon</h3>
            <p>
              Geleceğin dijital dünyasında lider olmayı hedefliyoruz. Yenilikçi teknolojiler
              ve sürdürülebilir çözümler ile sektöre yön veriyoruz.
            </p>
          </div>
          <div className="lg:w-1/2 text-center lg:text-left animate-fade-in-up">
            <h3 className="text-xl font-semibold mb-4">Misyon</h3>
            <p>
              Müşterilerimize değer katan çözümler sunarak, onların başarılarına katkıda bulunmak.
              Teknolojiyi insan odaklı bir yaklaşımla kullanarak topluma fayda sağlamak.
            </p>
          </div>
        </div>
      </div>

      {/* Services Section */}
      <h2 className="text-3xl font-semibold mb-6 animate-fade-in-down text-center lg:text-center text-black">Hizmetlerimiz</h2>
      <div className="inset-0 bg-gradient-to-r from-purple-700 via-pink-500 to-blue-500 animate-bgMove bg-[length:200%_200%] mb-12 px-4 py-16 rounded-lg shadow-lg">
        <div className="flex flex-col lg:flex-row lg:space-x-12">
          <div className="mb-8 lg:mb-0 lg:w-1/3 text-center lg:text-left animate-fade-in-up">
            <h3 className="text-xl font-semibold mb-4">CafeWare</h3>
            <p>
            Yapay Zeka Destekli Restoran Otomasyonu            </p>
          </div>
          <div className="mb-8 lg:mb-0 lg:w-1/3 text-center lg:text-left animate-fade-in-up">
            <h3 className="text-xl font-semibold mb-4">StockyWave</h3>
            <p>
            Yapay Zeka Destekli Stok Takibi            </p>
          </div>
          <div className="lg:w-1/3 text-center lg:text-left animate-fade-in-up">
            <h3 className="text-xl font-semibold mb-4">Chatbot</h3>
            <p>
            GPT Entegrasyonuna Sahip Kişiselleştirilmiş Chatbot            </p>
          </div>
        </div>
      </div>

      {/* Team Section */}
      <div className="mb-12 px-4 lg:px-0">
        <h2 className="text-3xl font-semibold mb-6 animate-fade-in-down text-center lg:text-center text-black">Ekibimiz</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {teamMembers.map((member, index) => (
            <div key={index} className="text-centerinset-0 bg-gradient-to-r from-purple-700 via-pink-500 to-blue-500 animate-bgMove bg-[length:200%_200%] p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition duration-300 animate-fade-in-up">
              <img
                src={member.image}
                alt={member.name}
                className="w-32 h-32 rounded-full mx-auto mb-4 object-cover shadow-md hover:shadow-xl transition duration-300"
              />
              <h3 className="text-xl font-semibold">{member.name}</h3>
              <p className="text-yellow-100">{member.role}</p>
              <p className="text-base mt-2 text-blue-100">{member.bio}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Contact Section */}
      <div className="mb-12 px-4 lg:px-0">
        <h2 className="text-3xl font-semibold mb-6 animate-fade-in-down text-center lg:text-center text-black">İletişim</h2>
        <div className="inset-0 bg-gradient-to-r from-purple-700 via-pink-500 to-blue-500 animate-bgMove bg-[length:200%_200%] text-white p-6 rounded-lg shadow-lg animate-fade-in-up text-center lg:text-left">
          <p className="text-base mb-4">
            Adres: <span className="font-semibold">1234 Şirket Cad., İstanbul, Türkiye</span>
          </p>
          <p className="text-base mb-4">
            Telefon: <span className="font-semibold">+90 123 456 7890</span>
          </p>
          <p className="text-base">
            E-posta: <span className="font-semibold">info@company.com</span>
          </p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default About;