import React from 'react';
import Navbar from './components/Navbar.jsx';
import { Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage.jsx";
import CafeWare from "./Pages/CafeWare.jsx";
import Chatbot from "./Pages/Chatbot.jsx";
import Contact from "./Pages/Contact.jsx";
import About from './Pages/About.jsx';
import Demo from "./Pages/Demo.jsx";
import StockyWave from "./Pages/StockyWave.jsx";
import Footer from './components//Footer.jsx';
import PolicyPage from './Pages/Policy.jsx';
function App() {
  return (
    
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/cafeware" element={<CafeWare />} />
        <Route path="/chatbot" element={<Chatbot />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/stockywave" element={<StockyWave />} />
        <Route path="/about" element={<About />} />
        <Route path="/policy" element={<PolicyPage />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
