import React from 'react';
import Hero from '../CafeWare-Components/Hero';
import Analytics from '../CafeWare-Components/Analytics';
import Cards from '../CafeWare-Components/Cards';
import Gallery from '../CafeWare-Components/Gallery';
const CafeWare = () => {
  return (
    <div>
        <Hero/>
        <Analytics/>
        <Cards/>
        <Gallery/>
    </div>
  );
};

export default CafeWare;
