import React from 'react';
import { FaShieldAlt, FaLock, FaUserSecret, FaDatabase, FaEnvelope, FaPhone } from 'react-icons/fa';

const PolicyPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-800 via-black to-blue-900 text-gray-200">

    <br/>
      <main className="max-w-5xl mx-auto py-16 px-4 sm:px-6 lg:px-8 space-y-20">
        {/* Privacy Policy Section */}
        <section id="gizlilik" className="bg-black bg-opacity-50 p-8 rounded-lg shadow-lg">
          <h2 className="text-4xl font-bold mb-4 text-white">Gizlilik Politikası</h2>
          <p className="text-lg leading-relaxed mb-6">
            MetaMind Evolution olarak gizliliğinize önem veriyoruz. Kişisel bilgilerinizin güvenliğini sağlamak için en yüksek
            düzeyde önlemler almaktayız. Bu politikada hangi bilgileri topladığımızı ve bu bilgileri nasıl kullandığımızı
            açıklıyoruz.
          </p>
          <h3 className="text-2xl font-semibold mb-2 flex items-center"><FaUserSecret className="mr-2" /> Topladığımız Bilgiler</h3>
          <ul className="list-disc list-inside pl-4 mb-4">
            <li>İsim ve İletişim Bilgileri</li>
            <li>Demografik Bilgiler</li>
            <li>Kullanım Verileri ve Çerezler</li>
          </ul>
          <h3 className="text-2xl font-semibold mb-2 flex items-center"><FaDatabase className="mr-2" /> Bilgilerin Kullanımı</h3>
          <p className="leading-relaxed mb-6">
            Toplanan bilgileri, hizmetlerimizi geliştirmek, kullanıcı deneyiminizi kişiselleştirmek ve sizinle iletişime geçmek
            için kullanırız.
          </p>
        </section>

        {/* GDPR/KVKK Compliance Section */}
        <section id="kvkk" className="bg-black bg-opacity-50 p-8 rounded-lg shadow-lg">
          <h2 className="text-4xl font-bold mb-4 text-white">KVKK Uyum</h2>
          <p className="text-lg leading-relaxed mb-6">
            KVKK (Kişisel Verilerin Korunması Kanunu) kapsamında, kişisel verilerinizi koruma sorumluluğumuzu ciddiyetle
            yerine getiriyoruz. Bu bölümde, verilerinizi nasıl koruduğumuz ve haklarınız hakkında bilgi veriyoruz.
          </p>
          <h3 className="text-2xl font-semibold mb-2 flex items-center"><FaShieldAlt className="mr-2" /> Veri Koruma Yöntemlerimiz</h3>
          <ul className="list-disc list-inside pl-4 mb-4">
            <li>Veri Şifreleme</li>
            <li>Güvenlik Duvarları</li>
            <li>Düzenli Güvenlik Denetimleri</li>
          </ul>
          <h3 className="text-2xl font-semibold mb-2 flex items-center"><FaLock className="mr-2" /> Haklarınız</h3>
          <p className="leading-relaxed mb-6">
            Kişisel verilerinize erişim talebinde bulunabilir, düzeltilmesini veya silinmesini isteyebilirsiniz. Ayrıca, belirli
            durumlarda verilerinizin işlenmesini kısıtlamak hakkına sahipsiniz.
          </p>
        </section>

        {/* Data Collection Section */}
        <section id="veri-toplama" className="bg-black bg-opacity-50 p-8 rounded-lg shadow-lg">
          <h2 className="text-4xl font-bold mb-4 text-white">Veri Toplama</h2>
          <p className="text-lg leading-relaxed mb-6">
            Hizmetlerimizi sunabilmek için bazı bilgileri toplarız. Bu bölümde, hangi bilgileri topladığımız ve bu bilgileri
            neden topladığımız hakkında bilgi veriyoruz.
          </p>
          <h3 className="text-2xl font-semibold mb-2 flex items-center"><FaDatabase className="mr-2" /> Toplama Amacı</h3>
          <p className="leading-relaxed mb-4">
            Hizmetlerimizin etkinliğini arttırmak, kullanıcı deneyimini iyileştirmek ve ihtiyaçlarınıza daha iyi yanıt
            verebilmek için verilerinizi toplarız.
          </p>
          <h3 className="text-2xl font-semibold mb-2 flex items-center"><FaDatabase className="mr-2" /> Toplanan Veriler</h3>
          <ul className="list-disc list-inside pl-4 mb-4">
            <li>Kullanıcı Etkileşim Verileri</li>
            <li>Cihaz ve Tarayıcı Bilgileri</li>
            <li>Konum Verileri</li>
          </ul>
        </section>

        {/* Security Measures Section */}
        <section id="guvenlik" className="bg-black bg-opacity-50 p-8 rounded-lg shadow-lg">
          <h2 className="text-4xl font-bold mb-4 text-white">Güvenlik Önlemleri</h2>
          <p className="text-lg leading-relaxed mb-6">
            Veri güvenliğiniz bizim için önemlidir. Bu bölümde, verilerinizi korumak için aldığımız güvenlik önlemlerini
            açıklıyoruz.
          </p>
          <h3 className="text-2xl font-semibold mb-2 flex items-center"><FaShieldAlt className="mr-2" /> Kullandığımız Teknolojiler</h3>
          <ul className="list-disc list-inside pl-4 mb-4">
            <li>Veri Şifreleme</li>
            <li>Güvenlik Protokolleri</li>
            <li>Güvenlik Duvarları</li>
          </ul>
          <h3 className="text-2xl font-semibold mb-2 flex items-center"><FaShieldAlt className="mr-2" /> Güvenlik Politikamız</h3>
          <p className="leading-relaxed mb-4">
            Düzenli olarak güvenlik değerlendirmeleri yapar ve teknolojik gelişmeleri takip ederek güvenlik önlemlerimizi
            güncelleriz.
          </p>
        </section>

        {/* Contact Section */}
        <section id="iletisim" className="bg-black bg-opacity-50 p-8 rounded-lg shadow-lg">
          <h2 className="text-4xl font-bold mb-4 text-white">İletişim</h2>
          <p className="text-lg leading-relaxed mb-6">
            Gizlilik politikamız hakkında daha fazla bilgi almak veya herhangi bir konuda bizimle iletişime geçmek için lütfen
            aşağıdaki bilgileri kullanın.
          </p>
          <div className="bg-gradient-to-r from-purple-500 to-indigo-500 text-white p-6 rounded-lg shadow-lg">
            <p className="mb-4 flex items-center"><FaEnvelope className="mr-2" /> <strong>Adres:</strong> 1234 Şirket Cad., İstanbul, Türkiye</p>
            <p className="mb-4 flex items-center"><FaPhone className="mr-2" /> <strong>Telefon:</strong> +90 123 456 7890</p>
            <p className="flex items-center"><FaEnvelope className="mr-2" /> <strong>E-posta:</strong> info@metamind.com</p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default PolicyPage;