import React from 'react';
import Laptop from '../assets/laptop.png';
import { Link } from "react-router-dom";

const Analytics = () => {
  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[1000px] mx-auto my-4' src={Laptop} alt='/' />
        <div className='flex flex-col justify-center'>
          <p className='text-[#df00c5] font-bold '>Yeni Nesil Hizmetlerimiz ile Şirketinizi Bir Adım Öteye Taşıyın</p>
          <h1 className='text-black md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Şirketinizi AI ile güçlendirin.</h1>
          <p className='text-black'>
            Şirketinizi yeni nesil otomasyon, analiz yazılımları ile yeni çağa ayak uydurmak ister misiniz? Cevabınız 'Evet' ise hemen iletişime geçin.
          </p>
          <Link to='/contact' className='bg-[#ff11d3] text-[#ffffff] w-[200px] text-center rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>İletişime Geçin</Link>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
