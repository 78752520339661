// Contact.js
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const Contact = () => {
  return (
    
    <div className="w-full min-h-screen inset-0 bg-gradient-to-r from-purple-800 via-black to-blue-900 animate-bgMove bg-[length:200%_200%] p-8 pt-24 relative">
    <br/>
      <div className="max-w-[1240px] mx-auto grid lg:grid-cols-2 gap-8">
        <div className="flex flex-col justify-center">
          <h2 className="text-4xl font-bold text-white mb-4">Bizimle İletişime Geçin</h2>
          <p className="text-white mb-6">Aşağıdaki iletişim bilgileri üzerinden bize ulaşabilirsiniz veya formu doldurarak mesajınızı iletebilirsiniz.</p>
          <MapContainer center={[39.925533, 32.866287]} zoom={13} scrollWheelZoom={false} className="w-full h-[400px] rounded-md z-10">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={[39.925533, 32.866287]}>
              <Popup>
                MetaMind Evolution <br /> İş Merkezimiz.
              </Popup>
            </Marker>
          </MapContainer>
        </div>

        {/* Contact Form Section */}
        <div className="bg-white p-8 rounded-md shadow-lg">
          <form action="https://formsubmit.co/your-email@example.com" method="POST" className="flex flex-col space-y-4">
            <input type="hidden" name="_next" value="https://yourdomain.com/thank-you" />
            <input type="hidden" name="_captcha" value="false" />

            <div>
              <label htmlFor="name" className="block text-gray-700 font-medium mb-2">Adınız</label>
              <input type="text" name="name" id="name" className="w-full p-3 border border-gray-300 rounded-md" required />
            </div>
            <div>
              <label htmlFor="email" className="block text-gray-700 font-medium mb-2">E-posta</label>
              <input type="email" name="email" id="email" className="w-full p-3 border border-gray-300 rounded-md" required />
            </div>
            <div>
              <label htmlFor="message" className="block text-gray-700 font-medium mb-2">Mesajınız</label>
              <textarea name="message" id="message" rows="5" className="w-full p-3 border border-gray-300 rounded-md" required></textarea>
            </div>
            <button type="submit" className="bg-[#c339a9] text-white p-3 rounded-md font-medium">Gönder</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;