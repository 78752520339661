import React from 'react';
import CafeWareLogo from '../assets/coffee-cup.png';

const Analytics = () => {
  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[300px] mx-auto my-4' src={CafeWareLogo} alt='/' />
        <div className='flex flex-col justify-center'>
          <p className='text-[#df00c5] font-bold '>Yapay Zeka ile günlendirilmiş Restoran Otomasyon Yazılımı</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>CafeWare</h1>
          <p class="text-justify">
          Modern restoran ve kafe işletmeleri için tasarlanan CafeWare, işletmenizin yönetim ve satış süreçlerini yeniden tanımlayan yenilikçi bir deneyim sunar. CafeWare, sektörünüzdeki rekabet avantajınızı artırmanıza yardımcı olmak amacıyla bir dizi gelişmiş özellik ile donatılmıştır.
          </p>
          <button className='bg-purple text-[#ffffff] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>İletişime Geçin</button>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
